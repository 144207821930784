const data = {
    prepaidCardStatus:{
        INACTIVE: 0,
        ACTIVE: 1,
        BLOCKED: 2,
        DELETED: 3
    },
    operationType: [
        {
            id: 1,
            descripcion: 'ADD FUNDS'

        },
        {
            id: 2,
            descripcion: 'SEND MONEY'

        },
        {
            id: 3,
            descripcion: 'WITHDRAW'
        },
        {
            id: 4,
            descripcion: 'CRYPTO'
        },
        {
            id: 5,
            descripcion: 'BUY DOLAR'
        },
        {
            id: 6,
            descripcion: 'SELL DOLAR'
        }

    ]


}

export default data
