<template>
    <div class="flex flex-wrap w-4/4">
        <data-view-sidebar :title="title" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

        <vx-card title="Cards">
<!--            <template slot="actions">-->
<!--                <feather-icon icon="UploadIcon" svgClasses="w-6 h-6 text-success" @click="addNewData"></feather-icon>-->
<!--            </template>-->

            <div slot="no-body" class="mt-4">

                <vs-table :data="user.cards">
                    <template slot="thead">
                        <vs-th>Cardholder Name</vs-th>
                        <vs-th>Card Number</vs-th>
                        <vs-th>Brand</vs-th>
                        <vs-th>Created</vs-th>
                        <vs-th>Status</vs-th>
                    </template>

                    <template>
                        <vs-tr :key="indextr" v-for="(card, indextr) in user.cards">
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{card.cardHolderName}}
                                        </span>
                            </vs-td>
                            <vs-td>
                                        <span class="flex items-center px-2 py-1 rounded">
                                            {{card.cardNumber}}
                                        </span>
                            </vs-td>

                            <vs-td>
                                <vs-td class="img-container">
                                    <img :src="require(`@/assets/images/cards/${card.brand}.png`)" style="width: 30%"/>
                                </vs-td>

                            </vs-td>
                            <vs-td>
                                <span>{{card.created.date | moment("DD/MM/YYYY")}}</span>
                            </vs-td>
                            <vs-td>
                                <vs-chip :color="getStatusColor(card.status)" class="product-order-status">{{ getStatusText(card.status)  }}</vs-chip>
                            </vs-td>


                        </vs-tr>
                    </template>
                </vs-table>
            </div>

        </vx-card>
    </div>
</template>


<script>
    import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
    import DataViewSidebar from '@/components/DataViewSidebar.vue'
    import data from '../helpers/data'
    import {mapState, mapActions} from 'vuex'

    export default {
        components: {
            StatisticsCardLine,
            DataViewSidebar
        },
        data() {
            return {
                prepaidCardStatus: data.prepaidCardStatus,
                addNewDataSidebar: false,
                sidebarData: {},
                title: 'Card Import'
            }
        },
        created() {
            this.getUserCards()
        },
        computed: {
            ...mapState({
                user: state => state.user

            })

        },
        methods: {
            ...mapActions('user', ['getUserCards']),
            getStatusColor(status) {
                if(status == this.prepaidCardStatus.INACTIVE) return "warning"
                if(status == this.prepaidCardStatus.ACTIVE) return "success"
                if(status == this.prepaidCardStatus.DELETED || status == this.prepaidCardStatus.BLOCKED) return "danger"
                return "primary"
            },
            getStatusText(status){
                if(status == this.prepaidCardStatus.INACTIVE) return "Inactive"
                if(status == this.prepaidCardStatus.ACTIVE) return "Active"
                if(status == this.prepaidCardStatus.BLOCKED) return "Blocked"
                if(status == this.prepaidCardStatus.DELETED ) return "Deleted"
                return ''
            },
            addNewData() {
                this.sidebarData = {}
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val=false) {
                this.addNewDataSidebar = val
            }
        }


    }

</script>
